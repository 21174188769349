export const CONFIG = {
  logo: "/logo.png",
  site_title: "Iris IOT Portal Management",
  Auth0Domain: "irisiotportalmanagement.uk.auth0.com",
  Auth0ClientId: "tXXqkUqejJyNKHOGrG0f8NZzrRmWw4PU",
  Auth0Audience: "https://manage-api.irisiotportal.co.uk",
  API_URL: "https://management-api.irisiotportal.co.uk/",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl",
  websiteURL: "https://manage.irisiotportal.co.uk",
  billingEnabled: false,
  deviceCost: 15,
  primaryColour: "#24b3bb",
  secondaryColour: "#224044",
};
